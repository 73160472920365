// Externals dependencies
import router from '@/router'
import { useStorage } from "vue3-storage";

// Services
import authServices from '@/services/auth/auth.services'

// Constants
import { AUTH_CLIENT_NAME, AUTH_TOKEN_NAME, AUTH_UID_NAME } from '@/constants/auth.constants'

const auth = {
  namespaced: true,

  state: {
    isAuthenticated: false,

    /** User Auth */
    user: {},

    /** Auth Credentials */
    accessToken: null, client: null, uid: null,
  },

  getters: {
    isAuthenticated: (state) => state.isAuthenticated,

    /** User Auth */
    user: (state) => state.user,

    /** Auth Credentials */
    accessToken: (state) => state.accessToken, client: (state) => state.client, uid: (state) => state.uid
  },

  mutations: {
    SET_TOKEN: (state, { accessToken, uid, client }) => {
      state.accessToken = accessToken
      state.uid = uid
      state.client = client
    },

    SET_IS_AUTHENTICATE: (state, isAuthenticated) => {
      state.isAuthenticated = isAuthenticated
    },

    SET_USER: (state, user) => {
      state.user = user
    },

    CLEAR_STORE: (state) => {
      state.user = {}
      state.isAuthenticated = false
      /** User Auth */
      state.user = {}

      /** Auth Credentials */
      state.accessToken = null
      state.client = null
      state.uid = null
    },
  },

  actions: {

    SignIn({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        authServices
          .login({ email, password })
          .then((response) => {
            console.log({ response })
            const { errors, success, data } = response.data

            if (errors && success === false) {
              reject(errors)

            } else {
              const client = response.headers['client']
              const accessToken = response.headers['access-token']
              const uid = response.headers['uid']

              /** Save token */
              const storage = useStorage()
              storage.setStorageSync(AUTH_CLIENT_NAME, client)
              storage.setStorageSync(AUTH_TOKEN_NAME, accessToken)
              storage.setStorageSync(AUTH_UID_NAME, uid)

              localStorage.setItem(AUTH_CLIENT_NAME, client)
              localStorage.setItem(AUTH_TOKEN_NAME, accessToken)
              localStorage.setItem(AUTH_UID_NAME, uid)
              commit('SET_TOKEN', { accessToken, uid, client })
              commit('SET_IS_AUTHENTICATE', true)

              /** Save User */
              commit('SET_USER', data)

              resolve(true)
            }
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.errors) {
              reject(error.response.data.errors)
            } else {
              reject(error)
            }
          })
      })
    },

    GetProfile({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        authServices
          .profile()
          .then((response) => {
            const { success, data, errors } = response.data
            if (success === false && errors) {
              reject(errors)
            } else {
              commit('SET_USER', data)
              resolve(data)
            }
          })
          .catch((error) => {
            dispatch('SignOut').finally(() => { router.push({ name: 'Login' }) })
            reject(error)
          })
      })
    },

    SignOut({ commit }) {
      return new Promise((resolve) => {
        /** Limpiar cache de apollo */
        authServices
          .logout()
          .finally(() => {
            /** Remover vue local storage */
            const storage = useStorage()
            storage.clearStorageSync()

            /** Remover local storage */
            localStorage.clear()

            /** Limpiar toda la store */
            commit('CLEAR_STORE')
            resolve(true)
          })

      })
    },

  }

}

export default auth
