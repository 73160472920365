/** Dependences */
import _ from 'lodash';

/** Internal Dependencies */
// @ts-ignore
import regionsServices from '@/services/global/regions.services'

/** Interfaces */
const region = {
  namespaced: true,
  state: {
    regions: [],
  },

  getters: {
    regions: (state) => state.regions,
    regionForSelect: (state) => {
      return _.toArray(_.mapValues(state.regions, (value) => {
        return { value: value.id, label: `${value.name}` };
      }));
    }
  },

  mutations: {

    CLEAR_STORE: (state) => {
      state.regions = [];
    },

    SET_REGIONS: (state, data) => {
      state.regions = data;
    },

  },

  actions: {

    clearStore({ commit }) {
      commit('CLEAR_STORE');
    },

    getRegions({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.regions.length === 0) {
          resolve(true)
        }
        regionsServices
          .index({})
          .then(( data) => {
            if (data.status !== 'error') {
              commit('SET_REGIONS',  data.data)
              resolve(true)
            } else {
              reject(data.message)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

  }
};

export default region;
