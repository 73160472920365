import axios from 'axios'
import config from '@/config'
import store from '@/store'
import { VueAxios } from './axios'
import { useStorage } from "vue3-storage";

// Constants
import { AUTH_CLIENT_NAME, AUTH_TOKEN_NAME, AUTH_UID_NAME } from '@/constants/auth.constants'

const service = axios.create({
  baseURL: config.urlApi,
  timeout: 30000
})

const err = (error) => {
  if (error.response && error.response.status === 401 && error.request && error.request.responseURL.indexOf('sign_in') <= -1) {
    store.dispatch('auth/SignOut')
      .finally(() => {
        window.location.href = '/login'
      })
  }
  return Promise.reject(error)
}

// request interceptor
// eslint-disable-next-line no-shadow
service.interceptors.request.use((config) => {
  const storage = useStorage()
  const accessToken = storage.getStorageSync(AUTH_TOKEN_NAME)
  const uid = storage.getStorageSync(AUTH_UID_NAME)
  const client = storage.getStorageSync(AUTH_CLIENT_NAME)
  if (accessToken && uid && client) {
    config.headers['access-token'] = accessToken
    config.headers['uid'] = uid
    config.headers['client'] = client
  }
  return config
}, err)

service.interceptors.response.use((response) => {
  return response
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export { installer as VueAxios, service as axios }
