import { createApp } from 'vue'
import App from './App.vue'
import Vue3Storage, { StorageType } from "vue3-storage";

/** Plugins */
import vuetify from './plugins/vuetify'
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import store from './store'
import './style/main.scss'

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(Vue3Storage, { namespace: "bo_edu", storage: StorageType.Local })
  .mount('#app')
