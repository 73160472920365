/** Dependences */
import _ from 'lodash';

/** Services */
import establishmentServices from '@/services/establishment/establishments.services';

const establishment = {
  namespaced: true,
  state: {
    establishments: [],
    selectedEstablishment: {}
  },

  getters: {
    establishments: (state) => state.establishments,
    selectedEstablishment: (state) => state.selectedEstablishment
  },

  mutations: {

    CLEAR_STORE: (state) => {
      state.establishments = [];
      state.selectedEstablishment = {};
    },

    SET_ESTABLISHMENTS: (state, establishments) => {
      state.establishments = establishments;
    },


    SET_SELECTED_ESTABLISHMENT: (state, establishmentItem) => {
      const establishmentItemNew = establishmentItem;
      establishmentItemNew.regionId = (establishmentItem.commune ? establishmentItem.commune.regionId : null);
      state.selectedEstablishment = establishmentItemNew;
    }

  },

  actions: {

    clearStore({ commit }) {
      commit('CLEAR_STORE');
    },

    setSelectedEstablishment({ state, commit, dispatch }, establishmentId) {
      const selectedEstablishment = _.find(state.establishments,
        (establishmentItem) => establishmentItem.id === establishmentId);
      if (selectedEstablishment) {
        commit('SET_SELECTED_ESTABLISHMENT', selectedEstablishment);
        dispatch('getEstablishment')
      }
    },

    getEstablishment({ commit, state }) {
      return new Promise((resolve, reject) => {
        const { selectedEstablishment } = state
        establishmentServices
          .show({ id: selectedEstablishment.id })
          .then(({ data }) => {
            if (data) {
              commit('SET_SELECTED_ESTABLISHMENT', data);
              resolve(true)
            } else {
              commit('CLEAR_STORE');
              resolve(false)
            }
          })
          .catch((error) => reject(error))
      })
    },

    getEstablishments({ commit }) {
      return new Promise((resolve, reject) => {
        establishmentServices
          .index()
          .then(({ data }) => {
            if (data) {
              commit('SET_ESTABLISHMENTS', data);
              if (data.length >= 0) commit('SET_SELECTED_ESTABLISHMENT', data[0]);
            } else {
              commit('CLEAR_STORE');
            }
          })
          .catch((error) => reject(error))
      })
    },

    updateEstablishment({ commit }, { id, form = {} }) {
      return new Promise((resolve, reject) => {
        establishmentServices
          .update({
            id,
            form
          })
          .then((response) => {
            const { data: { status, message, errors, establishment } } = response;
            if (status === 'success') {
              commit('SET_SELECTED_ESTABLISHMENT', establishment);
              resolve({ status, message, establishment });
            } else {
              resolve({ status, message, errors });
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default establishment;
