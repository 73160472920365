import { axios } from '@/utils/request';
import config from '@/config';

export function login({ email, password }) {
  return axios({
    url: `${config.urlApi}/api/v1/backoffice/auth/sign_in`,
    method: 'post',
    data: { email, password }
  });
}

export function logout() {
  return axios({
    url: `${config.urlApi}/api/v1/backoffice/auth/sign_out`,
    method: 'delete'
  });
}

export function profile() {
  return axios({
    url: `${config.urlApi}/api/v1/backoffice/auth/validate_token`,
    method: 'get'
  });
}

export default {
  login,
  logout,
  profile
};
