/** Dependences */
import _ from 'lodash';

/** Internal depedencies */
// @ts-ignore
import communeServices from '@/services/global/communes.services'

/** Queries */
const commune = {
  namespaced: true,
  state: {
    communes: [],
  },

  getters: {
    communes: (state) => state.communes,
    communesForSelect: (state) => (regionId) => {
      return regionId
        ? _.filter(state.communes,
          (communeItem) => communeItem.regionId === regionId)
        : state.communes;
    },
    communesForTableReference: (state) => {
      return _.toArray(_.mapValues(state.communes, (value) => {
        return { id: value.id, reference: value.name };
      }));
    }
  },

  mutations: {

    CLEAR_STORE: (state) => {
      state.communes = [];
    },

    SET_COMMUNES: (state, communes) => {
      state.communes = communes;
    },

  },

  actions: {

    clearStore({ commit }) {
      commit('CLEAR_STORE');
    },

    getCommunes({ commit }, { regionId = null }) {
      return new Promise((resolve, reject) => {
        communeServices
          .index({ search_by: { region_id: regionId } })
          .then(( data ) => {
            if (data.status !== 'error') {
              commit('SET_COMMUNES', data.data)
              resolve(true)
            } else {
              reject(data.message)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

  }
};

export default commune;
