import { axios } from '@/utils/request';
import config from '@/config';
import jsonParse from '@/utils/formDataHelper';

export function index() {
  return axios({
    url: `${config.urlApi}/api/v1/backoffice/establishments`,
    method: 'get'
  });
}

export function show({ id }) {
  return axios({
    url: `${config.urlApi}/api/v1/backoffice/establishments/${id}`,
    method: 'get'
  });
}

export function update({ id, form }) {
  const valuesFormData = jsonParse(form);
  return axios({
    url: `${config.urlApi}/api/v1/backoffice/establishments/${id}`,
    method: 'put',
    data: valuesFormData
  });
}

export function create({ form }) {
  const valuesFormData = jsonParse(form);
  return axios({
    url: `${config.urlApi}/api/v1/backoffice/establishments`,
    method: 'post',
    data: valuesFormData
  });
}

export function destroy({ id }) {
  return axios({
    url: `${config.urlApi}/api/v1/backoffice/establishments/${id}`,
    method: 'delete'
  })
}

export default {
  index,
  show,
  update,
  create,
  destroy
};
