import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

/** Modules */
/** Auth */
import auth from '@/store/modules/auth'
import establishment from './modules/establishment'
import commune from './global/commune'
import region from './global/region'

const modules = {
  /** Auth */
  auth,
  establishment,
  commune,
  region
}


export default new createStore({
  state: {
    profile: {}
  },
  mutations: {},
  actions: {
    clearStores({ commit }) {
      Object.keys(modules).forEach((module) => {
        commit(`${module}/CLEAR_STORE`)
      })
    }
  },
  modules,
  plugins: [
    createPersistedState({
      reducer: (persistedState) => {
        return JSON.parse(JSON.stringify(persistedState))
      }
    })
  ]
})
