import { axios } from '@/utils/request'
import config from '@/config'
import urlWithParams from '@/utils/uriHelper';

export function index({ search_by = {} }) {
  return axios({
    url: urlWithParams(`${config.urlApi}/api/v1/backoffice/communes`, {
      search_by
    }),
    method: 'get'
  })
}

export default {
  index
}
